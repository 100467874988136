import React from 'react'
import { Svg, Circle, Path } from '..'
import type { SvgProps } from './Svg'

export const PhoneCircle: React.FC<SvgProps> = ({
  fill = 'secondary',
  ...props
}) => {
  return (
    <Svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <Circle cx="17" cy="17" r="16.5" fill="transparent" stroke={fill} />
      <Path
        d="M21.9999 23.5H21.9149C12.0899 22.935 10.6949 14.645 10.4999 12.115C10.4842 11.9183 10.5074 11.7204 10.5683 11.5327C10.6292 11.345 10.7265 11.1712 10.8547 11.0211C10.9829 10.8711 11.1395 10.7478 11.3154 10.6584C11.4913 10.569 11.6831 10.5152 11.8799 10.5H14.6349C14.8352 10.4998 15.0309 10.5598 15.1967 10.6721C15.3625 10.7844 15.4908 10.9439 15.5649 11.13L16.3249 13C16.3981 13.1818 16.4162 13.381 16.3771 13.573C16.338 13.7651 16.2433 13.9413 16.1049 14.08L15.0399 15.155C15.2062 16.1004 15.659 16.9718 16.3368 17.6515C17.0147 18.3311 17.8849 18.7862 18.8299 18.955L19.9149 17.88C20.0556 17.7431 20.2336 17.6506 20.4265 17.6142C20.6195 17.5777 20.8189 17.5989 20.9999 17.675L22.8849 18.43C23.0681 18.5064 23.2245 18.6357 23.334 18.8013C23.4435 18.967 23.5013 19.1614 23.4999 19.36V22C23.4999 22.3978 23.3418 22.7794 23.0605 23.0607C22.7792 23.342 22.3977 23.5 21.9999 23.5ZM11.9999 11.5C11.8673 11.5 11.7401 11.5527 11.6463 11.6464C11.5526 11.7402 11.4999 11.8674 11.4999 12V12.04C11.7299 15 13.2049 22 21.9699 22.5C22.0356 22.5041 22.1014 22.4951 22.1636 22.4737C22.2259 22.4522 22.2832 22.4187 22.3325 22.375C22.3817 22.3314 22.4218 22.2784 22.4506 22.2192C22.4793 22.16 22.4961 22.0957 22.4999 22.03V19.36L20.6149 18.605L19.1799 20.03L18.9399 20C14.5899 19.455 13.9999 15.105 13.9999 15.06L13.9699 14.82L15.3899 13.385L14.6399 11.5H11.9999Z"
        fill={fill}
      />
    </Svg>
  )
}
