import React, { forwardRef } from 'react'
import { Box } from '../Box/Box'
import type { BoxProps } from '../Box/Box'

type IconButtonProps = BoxProps & React.ButtonHTMLAttributes<HTMLButtonElement>

export const IconButton = forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
      <Box
        ref={ref}
        as="button"
        type="button"
        bg="transparent"
        border="0"
        p="0"
        {...props}
      />
    )
  }
)

IconButton.displayName = 'IconButton'
