import React, { forwardRef } from 'react'
import { Input } from '..'
import type { BoxProps } from '../Box/Box'

type TextAreaAttributes = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'width' | 'height' | 'size'
>

export type TextAreaProps = TextAreaAttributes & BoxProps

export const TextArea = forwardRef(
  (props: TextAreaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    // FIXME: Omit doesn't seem to work, height of TextareaHTMLAttributes still conflicts with BoxProps
    // @ts-ignore
    return <Input ref={ref} as="textarea" {...props} />
  }
)

TextArea.displayName = 'TextArea'
