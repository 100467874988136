import React from 'react'
import { css } from '@emotion/core'
import { Box, Image } from '..'

export const PlayButton: React.FC<{
  onClick: () => void
  width?: string | number
}> = ({ onClick, width = 125 }) => {
  return (
    <Box
      as="button"
      bg="transparent"
      border="0"
      p="0"
      mx="auto"
      display="block"
      onClick={onClick}
      width={width}
      height={width}
    >
      <Image
        src="/img/play.svg"
        alt="play"
        width={width}
        height={width}
        css={css`
          &:hover {
            background: rgba(255, 255, 255, 0.3);
            border-radius: 50%;
          }
        `}
      />
    </Box>
  )
}
