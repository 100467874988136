import React from 'react'
import { css } from '@emotion/core'
import { Box, Image, Link, Text, Heading } from '..'

export const ProductColumn: React.FC<{
  to: string
  image: string
  heading: string
  subheading: string
}> = ({ to, image, heading, subheading }) => {
  const fillArrowClass = 'fill-arrow'

  return (
    <Box className="col col-12 col-md-4">
      <Box
        bg="white"
        borderRadius="30"
        overflow="hidden"
        boxShadow="0px 15px 30px rgba(0, 0, 0, 0.2)"
        transition="transform 150ms"
        mb="medium"
        css={css`
          &:hover {
            transform: scale(1.025);
          }
        `}
      >
        <Link
          to={to}
          textDecoration="none"
          css={css`
            &:hover .${fillArrowClass} {
              opacity: 1;
            }
          `}
        >
          <Image src={image} alt={image} width="100%" />
          <Box
            px={['xlarge', 'small', 'medium', 'xxlarge']}
            py={['large', 'small', 'medium', 'xlarge']}
          >
            <Text
              size="small"
              weight="bold"
              textTransform="uppercase"
              mb="small"
              color="textSecondary"
            >
              {subheading}
            </Text>
            <Heading level="3" mb="large" color="text">
              {heading}
            </Heading>
            <Box position="relative">
              <Image
                src="/img/green-arrow-outline.svg"
                alt="arrow"
                width="43"
                height="43"
              />
              <Image
                className={fillArrowClass}
                src="/img/green-arrow-fill.svg"
                alt="arrow"
                width="43"
                height="43"
                position="absolute"
                top="0"
                left="0"
                opacity="0"
                transition="opacity 150ms"
              />
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  )
}
