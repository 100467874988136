import React from 'react'
import { css } from '@emotion/core'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Container,
  Link,
  Image,
  Text,
  SmallAndBelow,
  MediumAndAbove
} from '..'
import { navMenus, NAVBAR_HEIGHT } from './Navbar'

const MobileNavLink: React.FC<{
  to: string
  title: string
  onClick: () => void
  inSubmenu?: boolean
  mb?: string
}> = ({ to, title, onClick, inSubmenu = false, ...props }) => {
  return (
    <Link
      to={to}
      py={inSubmenu ? 'xsmall' : 'small'}
      color="secondary"
      display="flex"
      textDecoration="none"
      weight="bold"
      onClick={onClick}
      size="large"
      pl={inSubmenu ? 'medium' : 'none'}
      textTransform="uppercase"
      {...props}
    >
      {title}
    </Link>
  )
}

export const MobileNav: React.FC<{
  visible: boolean
  onHideMobileNav: () => void
}> = ({ visible, onHideMobileNav }) => {
  return (
    <Box
      as="nav"
      bg="white"
      pt="medium"
      px="large"
      position="fixed"
      top="0"
      left="0"
      right="0"
      height={[1, 1, 400]}
      zIndex="1"
      transform={[
        `translateY(${visible ? `${NAVBAR_HEIGHT[0]}px` : '-100%'})`,
        `translateY(${visible ? `${NAVBAR_HEIGHT[1]}px` : '-100%'})`
      ]}
      transition="250ms"
      overflow="hidden"
      boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
    >
      <Container>
        <SmallAndBelow>
          <Accordion collapsible>
            {navMenus.map((navMenu) => (
              <AccordionItem
                key={navMenu.title}
                css={css`
                  &[data-reach-accordion-item][data-state='open']
                    [data-reach-accordion-button]
                    img {
                    transform: rotate(180deg);
                  }
                `}
                mb="medium"
              >
                <AccordionButton
                  py="small"
                  border="0"
                  display="flex"
                  width="100%"
                  bg="transparent"
                  alignItems="center"
                >
                  <Text
                    size="large"
                    color="secondary"
                    weight="bold"
                    mr="medium"
                    textTransform="uppercase"
                  >
                    {navMenu.title}
                  </Text>
                  <Image
                    src="/img/arrow-down.svg"
                    alt="expand"
                    transition="transform 200ms"
                  />
                </AccordionButton>
                {navMenu.links.map((link) => (
                  <AccordionPanel key={link.to}>
                    <MobileNavLink
                      inSubmenu
                      to={link.to}
                      title={link.title}
                      onClick={onHideMobileNav}
                    />
                  </AccordionPanel>
                ))}
              </AccordionItem>
            ))}
          </Accordion>
          <MobileNavLink
            to="/shipping-receiving"
            title="Shipping &amp; Receiving"
            onClick={onHideMobileNav}
            mb="medium"
          />
          <MobileNavLink
            to="/careers"
            title="Careers"
            onClick={onHideMobileNav}
            mb="medium"
          />
          <Button
            to="/contact"
            textTransform="uppercase"
            width="200"
            mx="auto"
            display="block"
            size="large"
            mt="100"
            py="small"
          >
            Contact Us
          </Button>
        </SmallAndBelow>
        <MediumAndAbove display="flex" mt="80">
          {navMenus.map((navMenu) => (
            <Box key={navMenu.title} mr="xxxlarge">
              <Text
                color="secondary"
                textTransform="uppercase"
                size="large"
                mb="medium"
                weight="bold"
              >
                {navMenu.title}
              </Text>
              {navMenu.links.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  display="block"
                  color="secondary"
                  weight="bold"
                  size="small"
                  py="small"
                  textDecoration="none"
                  textTransform="uppercase"
                >
                  {link.title}
                </Link>
              ))}
            </Box>
          ))}
          <Box flex="1" />
          <Box>
            <Link
              size="large"
              display="block"
              mb="large"
              color="secondary"
              weight="bold"
              textTransform="uppercase"
              textDecoration="none"
              to="/shipping-receiving"
            >
              Shipping &amp; Receiving
            </Link>
            <Link
              size="large"
              display="block"
              mb="large"
              color="secondary"
              weight="bold"
              textTransform="uppercase"
              textDecoration="none"
              to="/careers"
            >
              Careers
            </Link>
            <Button
              to="/contact"
              textTransform="uppercase"
              mt="xxxlarge"
              py="small"
              minWidth="145"
            >
              Contact Us
            </Button>
          </Box>
        </MediumAndAbove>
        {visible && (
          <Image
            src="/img/incobrasa-silhouette.svg"
            alt=""
            position="absolute"
            right="0"
            bottom={['50', '50', '-113']}
            pointerEvents="none"
          />
        )}
      </Container>
    </Box>
  )
}
