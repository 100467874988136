import React, { forwardRef } from 'react'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'

export type CircleProps = BoxProps &
  Omit<React.SVGAttributes<SVGCircleElement>, 'fill' | 'stroke'>

export const Circle = forwardRef(
  (props: CircleProps, ref: React.Ref<SVGCircleElement>) => {
    return <Box as="circle" ref={ref} {...props} />
  }
)

Circle.displayName = 'Circle'
