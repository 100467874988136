import React from 'react'
import { Box, Heading } from '..'
import { ProductColumn } from './ProductColumn'

export interface ProductLineProps {
  heading: string
  subheading: string
  items: Array<{
    heading: string
    subheading: string
    path: string
    image: string
  }>
}

export const ProductLine: React.FC<ProductLineProps> = ({
  heading,
  subheading,
  items
}) => {
  return (
    <>
      <Heading level="4" textTransform="uppercase" color="accent">
        {subheading}
      </Heading>
      <Heading
        level="2"
        textTransform="uppercase"
        mb={['large', 'xlarge', 'xxxlarge']}
      >
        {heading}
      </Heading>
      <Box className="row">
        {items.map((product) => (
          <ProductColumn
            key={product.heading}
            to={product.path}
            image={product.image}
            subheading={product.subheading}
            heading={product.heading}
          />
        ))}
      </Box>
    </>
  )
}
