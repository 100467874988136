import React from 'react'
import { Svg } from '..'
import { Path } from './Path'
import type { SvgProps } from './Svg'

export const CurveBreakBottom: React.FC<SvgProps> = ({
  fill = 'lime',
  ...props
}) => {
  return (
    <Svg
      width="100%"
      position="absolute"
      bottom="-1"
      viewBox="0 0 1440 141"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <Path
        d="M362.51 100.34C212.686 97.5301 58.41 107.196 0 112.38V141H1440V0C1423.26 10.7029 1361.97 39.1323 1250.71 67.2273C1111.63 102.346 942 112.38 833.975 112.38C723.823 112.38 549.791 103.852 362.51 100.34Z"
        fill={fill}
      />
    </Svg>
  )
}
