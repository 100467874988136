import React, { forwardRef } from 'react'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'

export type SvgProps = BoxProps &
  Omit<React.SVGAttributes<SVGElement>, 'display' | 'fill'>

export const Svg = forwardRef((props: SvgProps, ref: React.Ref<SVGElement>) => {
  return (
    <Box as="svg" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props} />
  )
})

Svg.displayName = 'Svg'
