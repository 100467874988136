import breakpoints from './breakpoints'
import { fontSizes, fontWeights } from './fonts'
import type { FontSize } from './fonts'
import spaces from './spaces'
import type { Space } from './spaces'

export type Theme = { [key: string]: any }
export type Size = string | number
export type ResponsiveSize = Size | Array<Size>
export type ResponsiveSpace = Space | Array<Space> | ResponsiveSize
export type ResponsiveFontSize = FontSize | Array<FontSize>
export type ResponsiveString = string | Array<string>
export type ResponsiveProp =
  | string
  | number
  | Array<string | number>
  | Array<string | number>

const colors = {
  primary: '#16582a',
  secondary: '#4b8b43',
  accent: '#f3c444',
  lime: '#f4f8f2',
  text: 'rgba(0, 0, 0, 0.8)',
  textSecondary: 'rgba(0, 0, 0, 0.5)'
}

const theme: Theme = {
  breakpoints: breakpoints,
  colors: colors,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  spaces: spaces,
  input: {
    '&:focus': {
      outline: 'none',
      boxShadow: `0px 0px 0px 2px ${colors.primary} inset`
    }
  },
  listboxButton: {
    '&:focus': {
      outline: 'none',
      boxShadow: `0px 0px 0px 2px ${colors.primary} inset`
    }
  },
  listboxOption: {
    '&[aria-selected="true"]': {
      bg: colors.secondary
    }
  },
  variants: {
    primary: {
      color: 'white',
      bg: 'secondary',
      '&:hover': {
        color: 'white',
        bg: 'primary'
      }
    },
    secondary: {
      color: 'secondary',
      bg: 'white',
      boxShadow: `0px 0px 0px 1px ${colors.secondary} inset`,
      '&:hover': {
        color: 'secondary',
        bg: 'lime'
      }
    },
    link: {
      bg: 'transparent',
      color: 'white',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}

export default theme
