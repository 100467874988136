import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  SmallAndAbove,
  MediumAndBelow,
  LargeAndAbove,
  XSmallAndBelow,
  Link,
  Text
} from '..'
import { useScrollPosition } from '../../hooks'
import { PhoneCircle } from '../Svg/PhoneCircle'
import { MailCircle } from '../Svg/MailCircle'
import { NavLink } from './NavLink'
import { NavMenu } from './NavMenu'

export const NAVBAR_HEIGHT = [50, 90]

export const navMenus = [
  {
    title: 'About',
    links: [
      { title: 'Our Company', to: '/our-company' },
      { title: 'Soybeans', to: '/soybeans' }
    ]
  },
  {
    title: 'Products',
    links: [
      { title: 'Vegetable Oil', to: '/products/vegetable-oil' },
      { title: 'Biodiesel', to: '/products/biodiesel' },
      { title: 'Animal Feed', to: '/products/animal-feed' }
    ]
  }
]

export const Navbar: React.FC<{
  transparentNavbarOnTop?: boolean
  mobileNavVisible?: boolean
  onToggleNavbar: () => void
}> = ({ transparentNavbarOnTop, onToggleNavbar, mobileNavVisible }) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const bgWhileOnTop = transparentNavbarOnTop ? 'transparent' : 'white'
  let navbarBg = hasScrolled ? 'white' : bgWhileOnTop
  if (mobileNavVisible) {
    navbarBg = 'white'
  }
  const linkColorOnTop = transparentNavbarOnTop ? 'white' : 'secondary'
  let linkColor = hasScrolled ? 'secondary' : linkColorOnTop
  if (mobileNavVisible) {
    linkColor = 'secondary'
  }

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0 && !hasScrolled) {
      setHasScrolled(true)
    } else if (currPos.y === 0 && hasScrolled) {
      setHasScrolled(false)
    }
  })

  return (
    <Flex
      as="nav"
      height={NAVBAR_HEIGHT}
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="2"
      transition="background 150ms"
      bg={navbarBg}
      boxShadow={
        hasScrolled && !mobileNavVisible
          ? '0px 5px 10px rgba(0, 0, 0, 0.1)'
          : 'none'
      }
    >
      <Container display="flex" height="100%" alignItems="center">
        <Link to="/" flexShrink="0" mr="large">
          <Image
            src="/img/incobrasa-logo.png"
            alt="incobrasa"
            width={[38, 55]}
            height={[38, 55]}
          />
        </Link>
        <LargeAndAbove>
          {navMenus.map((navMenu) => (
            <NavMenu
              key={navMenu.title}
              title={navMenu.title}
              linkColor={linkColor}
              links={navMenu.links}
            />
          ))}
          <NavLink linkColor={linkColor} to="/shipping-receiving">
            Shipping &amp; Receiving
          </NavLink>
          <NavLink linkColor={linkColor} to="/careers">
            Careers
          </NavLink>
          <Box flex="1" />
        </LargeAndAbove>
        <XSmallAndBelow flex="1" />
        <SmallAndAbove
          display="flex"
          height="100%"
          alignItems="center"
          flex="1"
          justifyContent={['center', 'center', 'center', 'flex-end']}
        >
          <Link
            display="flex"
            alignItems="center"
            mr="medium"
            color={linkColor}
            href="tel:+18152654803"
            textDecoration="none"
          >
            <PhoneCircle
              fill={linkColor}
              mr="small"
              display={['block', 'block', 'block', 'none', 'block']}
            />
            <Text size="small">815-265-4803</Text>
          </Link>
          <Link
            display="flex"
            alignItems="center"
            mr="medium"
            color={linkColor}
            href="mailto:info@incobrasa.com"
            textDecoration="none"
          >
            <MailCircle
              fill={linkColor}
              mr="small"
              display={['block', 'block', 'block', 'none', 'block']}
            />
            <Text size="small">info@incobrasa.com</Text>
          </Link>
        </SmallAndAbove>
        <LargeAndAbove>
          <Button to="/contact" minWidth="120" textTransform="uppercase">
            Contact
          </Button>
        </LargeAndAbove>
        <MediumAndBelow>
          <Button
            borderRadius="50%"
            flexShrink="0"
            width={[38, 50]}
            height={[38, 50]}
            onClick={onToggleNavbar}
            p="small"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src={mobileNavVisible ? '/img/close.svg' : '/img/hamburger.svg'}
              alt="menu"
            />
          </Button>
        </MediumAndBelow>
      </Container>
    </Flex>
  )
}
