import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import {
  ListboxInput as ReachListboxInput,
  ListboxButton as ReachListboxButton,
  ListboxPopover as ReachListboxPopover,
  ListboxList as ReachListboxList,
  ListboxOption as ReachListboxOption
} from '@reach/listbox'
import '@reach/listbox/styles.css'
import type {
  ListboxInputProps,
  ListboxButtonProps,
  ListboxPopoverProps,
  ListboxListProps,
  ListboxOptionProps
} from '@reach/listbox'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'
import { ChevronDown } from '../Svg/ChevronDown'

export const ListboxInput = forwardRef(
  (props: ListboxInputProps & BoxProps, ref: any) => {
    return <Box as={ReachListboxInput} ref={ref} {...props} />
  }
)

export const ListboxButton = forwardRef(
  (props: ListboxButtonProps & BoxProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <Box
        arrow={<ChevronDown width="18" height="14" mb="-2" />}
        css={css`
          [data-reach-listbox-arrow] {
            transition: transform 150ms;
            transform: scaleY(1);
          }
          [data-reach-listbox-arrow][data-expanded] {
            transform: scaleY(-1);
          }
        `}
        as={ReachListboxButton}
        ref={ref}
        element="listboxButton"
        bg="#f8f9fb"
        border="0"
        borderRadius="8"
        boxShadow="0px 0px 0px 0.5px #bbbbbb inset"
        fontFamily="inherit"
        lineHeight="1"
        px="medium"
        py="small"
        size="small"
        width="100%"
        {...props}
      />
    )
  }
)

export const ListboxPopover = forwardRef(
  (props: ListboxPopoverProps & BoxProps, ref: any) => {
    return (
      <Box
        as={ReachListboxPopover}
        ref={ref}
        element="listboxPopover"
        border="1px solid #bbbbbb"
        borderRadius="8"
        {...props}
      />
    )
  }
)

export const ListboxList = forwardRef(
  (props: ListboxListProps & BoxProps, ref: React.Ref<any>) => {
    return (
      <Box as={ReachListboxList} ref={ref} element="listboxList" {...props} />
    )
  }
)

export const ListboxOption = forwardRef(
  (props: ListboxOptionProps & BoxProps, ref: React.Ref<HTMLLIElement>) => {
    return (
      <Box
        as={ReachListboxOption}
        ref={ref}
        element="listboxOption"
        size="small"
        fontFamily="inherit"
        {...props}
      />
    )
  }
)

ListboxInput.displayName = 'ListboxInput'
ListboxButton.displayName = 'ListboxButton'
ListboxPopover.displayName = 'ListboxPopover'
ListboxList.displayName = 'ListboList'
ListboxOption.displayName = 'ListboxOption'

export { Listbox } from '@reach/listbox'
