import React, { useState, useRef } from 'react'
import {
  Button,
  Box,
  Input,
  TextArea,
  ListboxButton,
  ListboxInput,
  ListboxOption,
  ListboxPopover
} from '..'

const formTypes = [
  { name: 'general-inquiry', label: 'General Inquiry' },
  { name: 'human-resources', label: 'Employment or Human Resources Inquiry' },
  { name: 'sales', label: 'Sales or Purchasing Inquiry' },
  { name: 'shipping', label: 'Shipping or Receiving Inquiry' },
  { name: 'accounting', label: 'Accounting or Financial Inquiry' }
]

const FormFields: React.FC<{
  name: string
  values: { [name: string]: string }
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  onFormNameChange: (value: string) => void
}> = ({ name, values, onInputChange, onFormNameChange }) => {
  return (
    <>
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={name} />
      <Box className="row">
        <Box mb="medium" className="col col-12 col-md-6">
          <Input
            required
            type="text"
            name="firstName"
            placeholder="First Name"
            onChange={onInputChange}
            defaultValue={values.firstName}
          />
        </Box>
        <Box mb="medium" className="col col-12 col-md-6">
          <Input
            required
            type="text"
            name="lastName"
            placeholder="Last Name"
            onChange={onInputChange}
            defaultValue={values.lastName}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box mb="medium" className="col col-12 col-md-6">
          <Input
            required
            type="tel"
            name="phone"
            placeholder="Phone"
            onChange={onInputChange}
            defaultValue={values.phone}
          />
        </Box>
        <Box mb="medium" className="col col-12 col-md-6">
          <Input
            required
            type="email"
            name="email"
            placeholder="Email"
            onChange={onInputChange}
            defaultValue={values.email}
          />
        </Box>
      </Box>
      <ListboxInput
        mb="medium"
        onChange={onFormNameChange}
        defaultValue={values.inquiry}
      >
        <ListboxButton />
        <ListboxPopover>
          {formTypes.map(({ name, label }) => (
            <ListboxOption key={name} value={name}>
              {label}
            </ListboxOption>
          ))}
        </ListboxPopover>
      </ListboxInput>
      <TextArea
        required
        name="message"
        placeholder="Message"
        rows={10}
        style={{ resize: 'vertical' }}
        onChange={onInputChange}
        defaultValue={values.message}
      />
      <Button
        type="submit"
        width="60%"
        display="block"
        mx="auto"
        mt="xlarge"
        py="medium"
        size="large"
        textTransform="uppercase"
      >
        Send
      </Button>
    </>
  )
}

export const ContactForm: React.FC<unknown> = () => {
  // To use the built in netlify form notifications and route submissions for
  // each form type to different email addresses, we need to render a different
  // form with an appropriate `name` for each type.
  const [formName, setFormName] = useState(formTypes[0].name)
  const formValuesRef = useRef({})

  function updateValues(name: string, value: string) {
    formValuesRef.current = {
      ...formValuesRef.current,
      [name]: value
    }
  }

  function onInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    updateValues(event.target.name, event.target.value)
  }

  return (
    <>
      {formTypes.map(({ name }) => (
        <form
          key={name}
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name={name}
          style={{ display: formName === name ? 'initial' : 'none' }}
        >
          <FormFields
            // Rerender new FormFields by changing key when formName changes to
            // reset defaultValues and avoid using controlled components
            key={formName}
            name={name}
            values={formValuesRef.current}
            onInputChange={onInputChange}
            onFormNameChange={(value) => {
              updateValues('inquiry', value)
              setFormName(value)
            }}
          />
        </form>
      ))}
    </>
  )
}
