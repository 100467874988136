import React, { forwardRef } from 'react'
import { Link } from 'gatsby'
import { Box } from '../Box/Box'
import type { BoxProps } from '../Box/Box'

interface AnchorProps {
  href?: string
  target?: string
}

interface GatsbyLinkProps {
  to?: string
}

type LinkProps = AnchorProps | GatsbyLinkProps

type ButtonProps = LinkProps &
  BoxProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = forwardRef(
  (
    { as = 'button', children, type = 'button', ...props }: ButtonProps,
    ref: React.Ref<HTMLElement>
  ) => {
    if ((props as GatsbyLinkProps).to) {
      as = Link
    } else if ((props as AnchorProps).href) {
      as = 'a'
    }

    return (
      <Box
        as={as}
        ref={ref}
        // @ts-ignore
        type={as === 'button' ? type : undefined}
        border="0"
        borderRadius="500"
        cursor="pointer"
        display="inline-block"
        size="small"
        weight="bold"
        px="medium"
        py="xsmall"
        textAlign="center"
        textDecoration="none"
        variant="primary"
        {...props}
      >
        {children}
      </Box>
    )
  }
)

Button.displayName = 'Button'
