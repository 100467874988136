import React from 'react'
import marked from 'marked'
import { css } from '@emotion/core'
import type { SerializedStyles } from '@emotion/core'
import { Box } from '..'
import { BoxProps } from '../Box/Box'

interface MarkdownProps extends BoxProps {
  children: string
  css?: SerializedStyles
}

export const Markdown: React.FC<MarkdownProps> = ({
  children,
  css: cssStyles,
  ...props
}) => {
  return (
    <Box
      css={css`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p:not(:last-child),
        ul,
        ol,
        blockquote {
          margin-bottom: 16px;
        }
        ${cssStyles}
      `}
      dangerouslySetInnerHTML={{
        __html: marked(children || '')
      }}
      {...props}
    />
  )
}
