import React, { forwardRef } from 'react'
import {
  MenuList as ReachMenuList,
  MenuButton as ReachMenuButton,
  MenuLink as ReachMenuLink,
  MenuPopover as ReachMenuPopover
} from '@reach/menu-button'
import type { MenuLinkProps as ReachMenuLinkProps } from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import { Box, Link } from '..'
import { BoxProps } from '../Box/Box'
import { LinkProps } from '../Link/Link'

export const MenuList = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
    return <Box as={ReachMenuList} ref={ref} {...props} />
  }
)

export const MenuButton = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
    return <Box as={ReachMenuButton} ref={ref} {...props} />
  }
)

export const MenuLink = forwardRef(
  (
    props: ReachMenuLinkProps & LinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    return <ReachMenuLink as={Link} ref={ref} {...props} />
  }
)

export const MenuPopover = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
    return <Box as={ReachMenuPopover} ref={ref} {...props} />
  }
)

MenuList.displayName = 'MenuList'
MenuButton.displayName = 'MenuButton'
MenuLink.displayName = 'MenuLink'
MenuPopover.displayName = 'MenuPopover'

export { Menu } from '@reach/menu-button'
