import React, { forwardRef } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box } from '../Box/Box'
import type { BoxProps } from '../Box/Box'

interface AnchorProps {
  href: string
  target?: string
  rel?: string
}

interface GatsbyLinkProps {
  to: string
}

export type LinkProps = BoxProps & (AnchorProps | GatsbyLinkProps)

export const Link = forwardRef(
  (props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    let as: keyof JSX.IntrinsicElements | React.ComponentType<any>
    if ((props as GatsbyLinkProps).to) {
      as = GatsbyLink
    } else {
      as = 'a'
    }

    return <Box as={as} ref={ref} {...props} />
  }
)

Link.displayName = 'Link'
