import React from 'react'
import { Global, css } from '@emotion/core'

const styles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.8);
  }

  ::placeholder {
    color: #bbbbbb;
  }

  [data-reach-menu-popover] {
    z-index: 3;
  }
`

export const GlobalStyles: React.FC<unknown> = () => {
  return <Global styles={styles} />
}
