import React from 'react'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'

export const Card: React.FC<BoxProps> = (props) => {
  return (
    <Box
      bg="white"
      borderRadius="30"
      boxShadow="0px 15px 30px rgba(0, 0, 0, 0.2)"
      p="xlarge"
      {...props}
    />
  )
}
