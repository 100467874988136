import React from 'react'
import { Box, Flex, Button, SmallAndBelow } from '..'

export const VideoIframe: React.FC<{
  src: string
  onRequestClose: () => void
}> = ({ src, onRequestClose }) => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      alignItems="center"
      justifyContent="center"
      bg="rgba(0, 0, 0, 0.6)"
      zIndex="2"
    >
      <Box
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
        onClick={onRequestClose}
      />
      <SmallAndBelow>
        <Button
          variant="link"
          position="absolute"
          top="24"
          right="6"
          size="large"
          onClick={onRequestClose}
          zIndex="3"
        >
          Close
        </Button>
      </SmallAndBelow>
      <Flex justifyContent="center" position="relative">
        <iframe
          src={src}
          width="960"
          height="540"
          style={{ maxWidth: '90%' }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </Flex>
    </Flex>
  )
}
