import React, { forwardRef } from 'react'
import { SerializedStyles } from '@emotion/core'
import { useTheme, useStyleConfig } from '../../hooks'
import type { FontWeight } from '../../theme/fonts'
import type {
  ResponsiveString,
  ResponsiveProp,
  ResponsiveSize,
  ResponsiveFontSize,
  ResponsiveSpace
} from '../../theme/theme'
import styleConfig from './config'

export interface BoxProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'color' | 'onChange'> {
  alignContent?: ResponsiveString
  alignItems?: ResponsiveString
  alignSelf?: ResponsiveString
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  bg?: ResponsiveString
  backdropFilter?: ResponsiveString
  backgroundImage?: ResponsiveString
  backgroundPosition?: ResponsiveString
  backgroundSize?: ResponsiveString
  border?: ResponsiveString
  borderCollapse?: ResponsiveString
  borderColor?: ResponsiveString
  borderRadius?: ResponsiveString
  borderTopLeftRadius?: ResponsiveSize
  borderTopRightRadius?: ResponsiveSize
  borderBottomLeftRadius?: ResponsiveSize
  borderBottomRightRadius?: ResponsiveSize
  borderStyle?: ResponsiveString
  borderWidth?: ResponsiveString
  bottom?: ResponsiveSize
  boxShadow?: ResponsiveString
  boxSizing?: ResponsiveString
  color?: ResponsiveString
  columnGap?: ResponsiveSpace
  css?: SerializedStyles
  cursor?: ResponsiveString
  display?: ResponsiveString
  element?: string
  fill?: ResponsiveString
  filter?: ResponsiveString
  flex?: ResponsiveProp
  flexDirection?: ResponsiveString
  flexFlow?: ResponsiveString
  flexGrow?: ResponsiveString
  flexShrink?: ResponsiveString
  flexWrap?: ResponsiveString
  fontFamily?: ResponsiveString
  fontSize?: ResponsiveFontSize | ResponsiveSize
  fontWeight?: FontWeight
  gap?: ResponsiveString
  gridTemplateColumns?: ResponsiveString
  gridTemplateRows?: ResponsiveString
  height?: ResponsiveSize
  justifyContent?: ResponsiveString
  left?: ResponsiveSize
  lineClamp?: ResponsiveString
  lineHeight?: ResponsiveProp
  m?: ResponsiveSpace
  mt?: ResponsiveSpace
  mr?: ResponsiveSpace
  mb?: ResponsiveSpace
  ml?: ResponsiveSpace
  my?: ResponsiveSpace
  mx?: ResponsiveSpace
  minHeight?: ResponsiveSpace
  minWidth?: ResponsiveSpace
  maxHeight?: ResponsiveSpace
  maxWidth?: ResponsiveSpace
  objectFit?: ResponsiveSpace
  opacity?: ResponsiveProp
  order?: ResponsiveProp
  overflow?: ResponsiveString
  overflowX?: ResponsiveString
  overflowY?: ResponsiveString
  p?: ResponsiveSpace
  pt?: ResponsiveSpace
  pr?: ResponsiveSpace
  pb?: ResponsiveSpace
  pl?: ResponsiveSpace
  py?: ResponsiveSpace
  px?: ResponsiveSpace
  pointerEvents?: ResponsiveString
  position?: ResponsiveString
  right?: ResponsiveSize
  rowGap?: ResponsiveSpace
  size?: ResponsiveFontSize | ResponsiveSize
  stroke?: ResponsiveString
  textAlign?: ResponsiveString
  textDecoration?: ResponsiveString
  textOverflow?: ResponsiveString
  textShadow?: ResponsiveString
  textTransform?: ResponsiveString
  top?: ResponsiveSize
  transform?: ResponsiveString
  transition?: ResponsiveString
  webkitAppearance?: ResponsiveString
  weight?: FontWeight | Array<FontWeight>
  whiteSpace?: ResponsiveString
  width?: ResponsiveSize
  wordBreak?: ResponsiveString
  wordWrap?: ResponsiveString
  variant?: string
  verticalAlign?: ResponsiveString
  zIndex?: ResponsiveProp
}

export const Box = forwardRef(
  (
    { as = 'div', children, element, variant, ...props }: BoxProps,
    ref: React.Ref<HTMLElement | SVGElement>
  ) => {
    const theme = useTheme()
    const Component = as

    let elementStyles = {}
    if (element && element in theme) {
      elementStyles = theme[element]
    }

    let variantStyles = {}
    if (variant && variant in theme.variants) {
      variantStyles = theme.variants[variant]
    }

    const [styles, forwardedProps] = useStyleConfig(props, styleConfig, {
      ...elementStyles,
      ...variantStyles
    })

    return (
      <Component ref={ref} css={styles} {...forwardedProps}>
        {children}
      </Component>
    )
  }
)

Box.displayName = 'Box'
