import React, { forwardRef } from 'react'
import {
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import { Box } from '..'
import { BoxProps } from '../Box/Box'

export const AccordionItem = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
    return <Box as={ReachAccordionItem} ref={ref} {...props} />
  }
)

export const AccordionButton = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLButtonElement>) => {
    return <Box as={ReachAccordionButton} ref={ref} {...props} />
  }
)

export const AccordionPanel = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLDivElement>) => {
    return <Box as={ReachAccordionPanel} ref={ref} {...props} />
  }
)

AccordionItem.displayName = 'AccordionItem'
AccordionButton.displayName = 'AccordionButton'
AccordionPanel.displayName = 'AccordionPanel'

export { Accordion } from '@reach/accordion'
