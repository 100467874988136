import React from 'react'
import { Heading, Blockquote, Markdown } from '..'

export const LeadSection: React.FC<{ heading: string; text: string }> = ({
  heading,
  text
}) => {
  return (
    <>
      <Heading level="2" mb="large" textTransform="uppercase">
        {heading}
      </Heading>
      <Blockquote ml={['none', 'none', 'large', 'xxlarge']}>
        <Markdown size="small">{text}</Markdown>
      </Blockquote>
    </>
  )
}
