import React, { forwardRef } from 'react'
import { Box } from '../Box/Box'
import type { BoxProps } from '../Box/Box'

export type InputProps = BoxProps & React.InputHTMLAttributes<HTMLInputElement>

export const Input = forwardRef(
  (
    props: InputProps,
    ref: React.Ref<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    return (
      <Box
        as="input"
        ref={ref}
        element="input"
        bg="#f8f9fb"
        border="0"
        borderRadius="8"
        boxShadow="0px 0px 0px 0.5px #bbbbbb inset"
        fontFamily="inherit"
        size="small"
        px="medium"
        py="small"
        webkitAppearance="none"
        width="100%"
        {...props}
      />
    )
  }
)

Input.displayName = 'Input'
