import React from 'react'
import { Link } from '../'

export const NavLink: React.FC<{
  linkColor: string
  to: string
  children: React.ReactNode
}> = ({ to, linkColor, children }) => {
  return (
    <Link
      to={to}
      color={linkColor}
      size="small"
      textDecoration="none"
      weight="bold"
      px="small"
      py="small"
      textTransform="uppercase"
    >
      {children}
    </Link>
  )
}
