import React from 'react'
import { css } from '@emotion/core'
import { useTheme } from '../../hooks'
import { Menu, MenuList, MenuButton, MenuLink, MenuPopover } from '..'

export const NavMenu: React.FC<{
  title: string
  linkColor: string
  links: Array<{ to: string; title: string }>
}> = ({ title, links, linkColor }) => {
  const theme = useTheme()
  return (
    <Menu>
      <MenuButton
        border="0"
        bg="transparent"
        color={linkColor}
        weight="bold"
        size="small"
        cursor="pointer"
        px="small"
        py="small"
        textTransform="uppercase"
      >
        {title}
      </MenuButton>
      <MenuPopover>
        <MenuList
          py="small"
          px="none"
          bg="white"
          borderRadius="8"
          border="none"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.2)"
        >
          {links.map((link) => (
            <MenuLink
              key={link.to}
              to={link.to}
              color="secondary"
              weight="bold"
              size="small"
              position="relative"
              py="xsmall"
              textTransform="uppercase"
              css={css`
                &[data-selected] {
                  background: white;
                  color: ${theme.colors.primary};
                }
                &[data-selected]::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 4px;
                  background: ${theme.colors.primary};
                  border-radius: 100px;
                  height: 100%;
                }
              `}
            >
              {link.title}
            </MenuLink>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
