import React, { forwardRef } from 'react'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'

export type PathProps = BoxProps &
  Omit<React.SVGAttributes<SVGPathElement>, 'fill'>

export const Path = forwardRef(
  (props: PathProps, ref: React.Ref<SVGPathElement>) => {
    return <Box as="path" ref={ref} {...props} />
  }
)

Path.displayName = 'Path'
