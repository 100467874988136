import React from 'react'
import { Svg } from '..'
import type { SvgProps } from './Svg'

export const CurveBreakTop: React.FC<SvgProps> = ({
  fill = '#f4f8f2',
  ...props
}) => {
  return (
    <Svg
      width="100%"
      viewBox="0 0 1440 141"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M1077 85.5C1288.5 81.534 1313 85.5 1440 112.38V141H0V0C16.7365 10.7029 78.0251 39.1323 189.289 67.2273C328.368 102.346 498 112.38 606.025 112.38C716.177 112.38 889.72 89.0119 1077 85.5Z"
        fill={fill}
      />
    </Svg>
  )
}
