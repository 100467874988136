import React, { forwardRef } from 'react'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'

interface ContainerProps extends BoxProps {
  wide?: boolean
}

const maxWidths = [1, 1, 720, 960, 1140]
const wideMaxWidths = [1, 1, 1, 1, 1340]

export const Container = forwardRef(
  (
    { children, wide = false, ...props }: ContainerProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <Box
        mx="auto"
        px="medium"
        width="100%"
        maxWidth={wide ? wideMaxWidths : maxWidths}
        ref={ref}
        {...props}
      >
        {children}
      </Box>
    )
  }
)

Container.displayName = 'Container'
