import React from 'react'
import { css } from '@emotion/core'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'
import breakpoints from '../../theme/breakpoints'

const smBreakpoint = breakpoints[0] + 'px'
const mdBreakpoint = breakpoints[1] + 'px'
const lgBreakpoint = breakpoints[2] + 'px'
const xlBreakpoint = breakpoints[3] + 'px'

export const XSmallAndBelow: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (min-width: ${smBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const SmallOnly: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${smBreakpoint}) {
          display: none !important;
        }

        @media (min-width: ${mdBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const SmallAndBelow: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (min-width: ${mdBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const SmallAndAbove: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${smBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const MediumOnly: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${mdBreakpoint}) {
          display: none !important;
        }

        @media (min-width: ${lgBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const MediumAndBelow: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (min-width: ${lgBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const MediumAndAbove: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${mdBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const LargeOnly: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${lgBreakpoint}) {
          display: none !important;
        }

        @media (min-width: ${xlBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const LargeAndBelow: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (min-width: ${lgBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const LargeAndAbove: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${lgBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}

export const XLargeAndAbove: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      css={css`
        @media (max-width: ${xlBreakpoint}) {
          display: none !important;
        }
      `}
      {...props}
    >
      {children}
    </Box>
  )
}
