import React, { forwardRef } from 'react'
import { css } from '@emotion/core'
import { useTheme } from '../../hooks'
import { Box } from '..'
import type { BoxProps } from '../Box/Box'

export const Blockquote = forwardRef(
  (props: BoxProps, ref: React.Ref<HTMLElement>) => {
    const theme = useTheme()
    return (
      <Box
        as="blockquote"
        ref={ref}
        position="relative"
        pl="large"
        css={css`
          &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            height: 50px;
            border-radius: 100px;
            width: 5px;
            background: ${theme.colors.accent};
          }
        `}
        {...props}
      />
    )
  }
)

Blockquote.displayName = 'Blockquote'
