import React from 'react'
import { Text } from '../Text/Text'
import type { BoxProps } from '../Box/Box'

export interface AnchorProps extends BoxProps {
  href: string
}

export const Anchor: React.FC<AnchorProps> = ({ children, ...props }) => {
  return (
    <Text as="a" {...props}>
      {children}
    </Text>
  )
}
