import React from 'react'
import {
  Anchor,
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  SmallAndBelow,
  MediumAndAbove
} from '..'
import { CurveBreakTop } from '../Svg/CurveBreakTop'

export const Footer: React.FC<unknown> = () => {
  return (
    <footer>
      <CurveBreakTop mb="-8" />
      <Box bg="lime" pt="xxlarge" pb="xlarge">
        <Container>
          <Flex wrap="wrap">
            <Box
              mb="large"
              flex={['auto', 'auto', 1]}
              width={['100%', '100%', 'auto']}
            >
              <Heading level="4" textTransform="uppercase" mb="xxsmall">
                Incobrasa Industries, Ltd.
              </Heading>
              <Text size="small">
                540 East US Highway 24 Gilman, Illinois 60938
              </Text>
              <div>
                <Anchor
                  size="small"
                  href="tel:+18152654803"
                  color="secondary"
                  textDecoration="none"
                >
                  815-265-4803
                </Anchor>
              </div>
              <Anchor
                size="small"
                href="mailto:info@incobrasa.com"
                color="secondary"
                textDecoration="none"
              >
                info@incobrasa.com
              </Anchor>
            </Box>
            <MediumAndAbove>
              <Image
                src="/img/incobrasa-logo.png"
                alt="incobrasa"
                width="112"
                height="112"
                mx="85"
              />
            </MediumAndAbove>
            <Box
              mb="large"
              flex={['auto', 'auto', 1]}
              width={['100%', '100%', 'auto']}
            >
              <Heading level="4" textTransform="uppercase" mb="xxsmall">
                Merchandising Office
              </Heading>
              <div>
                <Anchor
                  size="small"
                  href="tel:+18773378488"
                  color="secondary"
                  textDecoration="none"
                >
                  Toll Free 877-337-8488
                </Anchor>
              </div>
              <Anchor
                size="small"
                href="mailto:incobrasa@incobrasa.com"
                color="secondary"
                textDecoration="none"
              >
                incobrasa@incobrasa.com
              </Anchor>
            </Box>
          </Flex>
          <SmallAndBelow>
            <Image
              src="/img/incobrasa-logo.png"
              alt="incobrasa"
              width="76"
              height="76"
              mx="auto"
            />
          </SmallAndBelow>
          <Box textAlign="center" pt="large">
            <Text size="xsmall">
              © {new Date().getFullYear()} Incobrasa Industries, Ltd. All Rights
              Reserved.
            </Text>
            <Box>
              <Link
                color="text"
                to="/privacy-statement"
                size="xsmall"
                textDecoration="none"
              >
                Privacy
              </Link>
              <Text size="xsmall" as="span">
                {' '}
                &amp;{' '}
              </Text>
              <Link
                color="text"
                to="/terms-of-use"
                size="xsmall"
                textDecoration="none"
              >
                Terms
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box bg="primary" height="20" />
    </footer>
  )
}
