import { useEffect } from 'react'
import debounce from 'lodash/debounce'

interface WindowSize {
  innerWidth: number
  innerHeight: number
  outerWidth: number
  outerHeight: number
}

function getWindowSize() {
  return {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight
  }
}

export function useWindowSize(
  effect: (windowSize: WindowSize) => void,
  debounceTime = 200
): void {
  useEffect(() => {
    function onResize() {
      effect(getWindowSize())
    }

    let resizeCallback = onResize
    if (debounceTime) {
      resizeCallback = debounce(onResize, debounceTime)
    }

    window.addEventListener('resize', resizeCallback)
    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  }, [effect, debounceTime])
}
