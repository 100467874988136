import { useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

interface ScrollPosition {
  x: number
  y: number
  offset: {
    top: number
    bottom: number
  }
}

function getScrollPosition() {
  if (!(typeof window !== 'undefined')) {
    return { x: 0, y: 0, offset: { top: 0, bottom: 0 } }
  }

  const position = document.body.getBoundingClientRect()

  return {
    x: position.left,
    y: position.top,
    offset: {
      top: window.scrollY,
      bottom: document.body.scrollHeight - window.innerHeight - window.scrollY
    }
  }
}

export default function useScrollPosition(
  effect: (scrollPosition: {
    prevPos: ScrollPosition
    currPos: ScrollPosition
  }) => void,
  debounceTime?: number
): void {
  const position = useRef<ScrollPosition>(getScrollPosition())

  useEffect(() => {
    if (!(typeof window !== 'undefined')) {
      return
    }

    function onScroll() {
      const currentPos = getScrollPosition()
      effect({ prevPos: position.current, currPos: currentPos })
      position.current = currentPos
    }

    let scrollCallback = onScroll
    if (debounceTime) {
      scrollCallback = debounce(onScroll, debounceTime)
    }

    window.addEventListener('scroll', scrollCallback)
    return () => {
      window.removeEventListener('scroll', scrollCallback)
    }
  }, [effect, debounceTime])
}
