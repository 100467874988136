import React, { useState } from 'react'
import {
  Box,
  Heading,
  Flex,
  IconButton,
  Image,
  Text,
  SmallAndBelow,
  MediumAndAbove,
  MediumAndBelow,
  LargeOnly,
  LargeAndAbove,
  XLargeAndAbove
} from '..'
import type { BoxProps } from '../Box/Box'

const ProductImage: React.FC<{ src: string } & BoxProps> = ({
  src,
  ...props
}) => {
  return (
    <Image
      src={src}
      alt={src}
      position="absolute"
      transition="500ms"
      top="0"
      left="0"
      {...props}
    />
  )
}

export interface ProductTableProps {
  table: {
    images: Array<{
      image: string
    }>
    descriptions: Array<{
      heading: string
      text: string
      images: Array<{
        image: string
        label?: string
      }>
    }>
  }
}

const tableHeight = 576
const xlargeTableWidth = 760
const largeTableWidth = 650

const smallImageSize = 130
const mediumImageSize = 180
const largeImageSize = 350

const imageMargin = 10

interface ImageLayoutAttrs {
  width: number
  height: number
  transform: string
}

const xlargeImgAttrs: {
  [key: number]: ImageLayoutAttrs
} = {
  '-1': {
    width: smallImageSize,
    height: smallImageSize,
    transform: `translate3d(-${smallImageSize + imageMargin * 2}px, -10px, 0px)`
  },
  0: {
    width: smallImageSize,
    height: smallImageSize,
    transform: `translate3d(${imageMargin}px, -10px, 0px)`
  },
  1: {
    width: mediumImageSize,
    height: mediumImageSize,
    transform: `translate3d(${smallImageSize + imageMargin * 2}px, -50px, 0px)`
  },
  2: {
    width: largeImageSize,
    height: largeImageSize,
    transform: `translate3d(calc(${xlargeTableWidth}px - 120%), calc(${
      tableHeight / 2 - 40
    }px - 50%), 0px)`
  },
  3: {
    width: mediumImageSize,
    height: mediumImageSize,
    transform: `translate3d(${
      smallImageSize + imageMargin * 2
    }px, calc(${tableHeight}px - 100% - 25px), 0px)`
  },
  4: {
    width: smallImageSize,
    height: smallImageSize,
    transform: `translate3d(${imageMargin}px, calc(${tableHeight}px - 100% - 50px), 0px)`
  },
  5: {
    width: smallImageSize,
    height: smallImageSize,
    transform: `translate3d(-${
      smallImageSize + imageMargin * 2
    }px, calc(${tableHeight}px - 100% - 50px), 0px)`
  }
}

const largeImgAttrs: {
  [key: number]: ImageLayoutAttrs
} = {
  '-1': {
    width: mediumImageSize,
    height: mediumImageSize,
    transform: `translate3d(-${
      mediumImageSize + imageMargin * 2
    }px, -50px, 0px)`
  },
  0: {
    width: mediumImageSize,
    height: mediumImageSize,
    transform: `translate3d(${imageMargin}px, -50px, 0px)`
  },
  1: {
    width: largeImageSize,
    height: largeImageSize,
    transform: `translate3d(calc(${largeTableWidth}px - 125%), calc(${
      tableHeight / 2 - 20
    }px - 50% - 50px), 0px)`
  },
  2: {
    width: mediumImageSize,
    height: mediumImageSize,
    transform: `translate3d(${imageMargin}px, calc(${tableHeight}px - 100% - 100px), 0px)`
  },
  3: {
    width: mediumImageSize,
    height: mediumImageSize,
    transform: `translate3d(-${
      mediumImageSize + imageMargin * 2
    }px, calc(${tableHeight}px - 100% - 100px), 0px)`
  }
}

const maxOffset = 1
const minOffset = -1

export const ProductTable: React.FC<ProductTableProps> = ({
  table: { images, descriptions }
}) => {
  const [offset, setOffset] = useState(0)
  const description = descriptions[offset + 1]
  const clockwiseDisabled = offset >= maxOffset
  const counterClockwiseDisabled = offset <= minOffset

  function rotateClockwise() {
    if (!clockwiseDisabled) {
      setOffset(offset + 1)
    }
  }

  function rotateCounterClockwise() {
    if (!counterClockwiseDisabled) {
      setOffset(offset - 1)
    }
  }

  function getImageLayout(
    imageIndex: number,
    imgAttrs: { [key: number]: ImageLayoutAttrs }
  ): ImageLayoutAttrs {
    const index = imageIndex + offset
    const imageIndexes = Object.keys(imgAttrs).map((key) => Number(key))
    const maxImageIndex = Math.max(...imageIndexes)
    const minImageIndex = Math.min(...imageIndexes)
    if (index > maxImageIndex) {
      return imgAttrs[minImageIndex]
    }
    if (index < minImageIndex) {
      return imgAttrs[maxImageIndex]
    }
    return imgAttrs[index]
  }

  const controlButtons = (
    <>
      <IconButton
        disabled={counterClockwiseDisabled}
        onClick={rotateCounterClockwise}
        mr="xlarge"
      >
        <Image
          width="43"
          height="43"
          alt="left"
          src={
            counterClockwiseDisabled
              ? '/img/left-circle-arrow-disabled.svg'
              : '/img/left-circle-arrow.svg'
          }
        />
      </IconButton>
      <IconButton disabled={clockwiseDisabled} onClick={rotateClockwise}>
        <Image
          width="43"
          height="43"
          alt="right"
          src={
            clockwiseDisabled
              ? '/img/right-circle-arrow-disabled.svg'
              : '/img/right-circle-arrow.svg'
          }
        />
      </IconButton>
    </>
  )

  return (
    <Flex
      pt={[50, 50, 50, 100]}
      mb="100"
      pb={['xxxlarge', 'xxxlarge', 'xxxlarge', 'none']}
      bg={['lime', 'lime', 'lime', 'white']}
      wrap="wrap"
    >
      <LargeAndAbove width="55%" maxWidth="760" position="relative">
        <Image
          src="/img/product-table.png"
          alt=""
          ml="-100"
          maxWidth="auto"
          width={[
            largeTableWidth,
            largeTableWidth,
            largeTableWidth,
            largeTableWidth,
            xlargeTableWidth
          ]}
        />
        <LargeOnly>
          <ProductImage
            src={images[3].image}
            {...getImageLayout(-1, largeImgAttrs)}
          />
          <ProductImage
            src={images[1].image}
            {...getImageLayout(0, largeImgAttrs)}
            onClick={() => setOffset(1)}
          />
          <ProductImage
            src={images[2].image}
            {...getImageLayout(1, largeImgAttrs)}
            onClick={() => setOffset(0)}
          />
          <ProductImage
            src={images[3].image}
            {...getImageLayout(2, largeImgAttrs)}
            onClick={() => setOffset(-1)}
          />
          <ProductImage
            src={images[1].image}
            {...getImageLayout(3, largeImgAttrs)}
          />
        </LargeOnly>
        <XLargeAndAbove>
          <ProductImage
            src={images[4].image}
            {...getImageLayout(-1, xlargeImgAttrs)}
          />
          <ProductImage
            src={images[0].image}
            {...getImageLayout(0, xlargeImgAttrs)}
          />
          <ProductImage
            src={images[1].image}
            {...getImageLayout(1, xlargeImgAttrs)}
            onClick={() => setOffset(1)}
          />
          <ProductImage
            src={images[2].image}
            {...getImageLayout(2, xlargeImgAttrs)}
            onClick={() => setOffset(0)}
          />
          <ProductImage
            src={images[3].image}
            {...getImageLayout(3, xlargeImgAttrs)}
            onClick={() => setOffset(-1)}
          />
          <ProductImage
            src={images[4].image}
            {...getImageLayout(4, xlargeImgAttrs)}
          />
          <ProductImage
            src={images[0].image}
            {...getImageLayout(5, xlargeImgAttrs)}
          />
        </XLargeAndAbove>
      </LargeAndAbove>
      <MediumAndBelow
        width={[1, 1, 1 / 3]}
        pl={['none', 'none', 'xlarge']}
        position="relative"
      >
        <Image
          mx="auto"
          mb={['large', 'large', 'none']}
          height={[248, 248, 'auto']}
          src={images[2 - offset].image}
          alt={images[2 - offset].image}
        />
        <SmallAndBelow
          display="flex"
          position="absolute"
          top="50%"
          left="0"
          width="100%"
          px="large"
          transform="translateY(-50%)"
          alignItems="center"
          justifyContent="space-between"
        >
          {controlButtons}
        </SmallAndBelow>
      </MediumAndBelow>
      <Box
        width={[1, 1, 2 / 3, '45%']}
        pl="xlarge"
        pr={['xlarge', 'xlarge', 'xlarge', 'xlarge', 'xxxlarge']}
      >
        <Box mt={[0, 0, 0, 60]} minHeight={[0, 0, 0, 390]}>
          <Box mb="large" textAlign={['center', 'center', 'left']}>
            <Heading level="2" mb="medium" textTransform="uppercase">
              {description.heading}
            </Heading>
            <Text size="small">{description.text}</Text>
          </Box>
          <Box className="row">
            {description.images.map(({ image, label }) => (
              <Box key={image} className="col col-3">
                <Image src={image} alt={image} />
                {label && (
                  <Text textAlign="center" mt="small" textTransform="uppercase">
                    {label}
                  </Text>
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <MediumAndAbove display="flex" mt="xxlarge">
          {controlButtons}
        </MediumAndAbove>
      </Box>
    </Flex>
  )
}
