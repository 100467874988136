export interface FontSizes {
  xxsmall: string
  xsmall: string
  small: string
  medium: string
  large: string
  xlarge: string
  xxlarge: string
  xxxlarge: string
}

export type FontSize = keyof FontSizes

export const fontSizes: FontSizes = {
  xxsmall: '10px',
  xsmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  xlarge: '24px',
  xxlarge: '36px',
  xxxlarge: '48px'
}

export interface FontWeights {
  thin: '100'
  extraLight: '200'
  light: '300'
  normal: '400'
  medium: '500'
  semiBold: '600'
  bold: '700'
  extraBold: '800'
  black: '900'
}

export type FontWeight = keyof FontWeights

export const fontWeights: FontWeights = {
  thin: '100',
  extraLight: '200',
  light: '300',
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  black: '900'
}
