import React from 'react'
import { Heading, Image, Text, Container, Box, Flex, PlayButton } from '..'
import { CurveBreakBottom } from '../Svg/CurveBreakBottom'

export const HeroSection: React.FC<{
  heading: string
  subheading: string
  text: string
  background: string
  hasVideo?: boolean
  onClickPlay?: () => void
  curveBreakFill?: string | Array<string>
}> = ({
  heading,
  subheading,
  text,
  background,
  hasVideo,
  onClickPlay = () => {},
  curveBreakFill = 'white'
}) => {
  return (
    <Box
      position="relative"
      minHeight={[350, 350, 400, 485]}
      pt="140"
      pb="xxlarge"
      backgroundImage={`url('${background}')`}
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <Image src={background} alt="" display="none" />
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(0, 0, 0, 0.6)"
      />
      <Container>
        <Flex wrap="wrap" alignItems="center" position="relative">
          <Box width={[1, 1, 2 / 3]}>
            <Heading level="3" textTransform="uppercase" color="accent">
              {subheading}
            </Heading>
            <Heading
              level="1"
              textTransform="uppercase"
              color="white"
              mb="medium"
            >
              {heading}
            </Heading>
            <Text size="small" color="white" whiteSpace="break-spaces">
              {text}
            </Text>
          </Box>
          {hasVideo && (
            <Box width={[1, 1, 1 / 3]} pt={['xlarge', 'xlarge', 'none']}>
              <PlayButton width="108" onClick={onClickPlay} />
            </Box>
          )}
        </Flex>
      </Container>
      <CurveBreakBottom fill={curveBreakFill} />
    </Box>
  )
}
