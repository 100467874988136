import React from 'react'
import { Svg, Circle, Path } from '..'
import type { SvgProps } from './Svg'

export const MailCircle: React.FC<SvgProps> = ({
  fill = 'secondary',
  ...props
}) => {
  return (
    <Svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <Circle cx="17" cy="17" r="16.5" fill="transparent" stroke={fill} />
      <Path
        d="M23 12H11C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13V21C10 21.2652 10.1054 21.5196 10.2929 21.7071C10.4804 21.8946 10.7348 22 11 22H23C23.2652 22 23.5196 21.8946 23.7071 21.7071C23.8946 21.5196 24 21.2652 24 21V13C24 12.7348 23.8946 12.4804 23.7071 12.2929C23.5196 12.1054 23.2652 12 23 12ZM21.9 13L17 16.39L12.1 13H21.9ZM11 21V13.455L16.715 17.41C16.7987 17.4681 16.8981 17.4992 17 17.4992C17.1019 17.4992 17.2013 17.4681 17.285 17.41L23 13.455V21H11Z"
        fill={fill}
      />
    </Svg>
  )
}
