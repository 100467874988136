import React from 'react'
import { Svg } from '..'
import type { SvgProps } from './Svg'

export const ChevronDown: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="16" viewBox="0 0 20 16" fill="none" {...props}>
      <path
        d="M9.3155 13.0457C9.39195 13.1345 9.49424 13.2071 9.61357 13.2573C9.7329 13.3075 9.86569 13.3337 10.0005 13.3337C10.1353 13.3337 10.2681 13.3075 10.3874 13.2573C10.5068 13.2071 10.609 13.1345 10.6855 13.0457L18.1855 4.37901C18.2723 4.27905 18.3232 4.16196 18.3327 4.04048C18.3422 3.91899 18.3098 3.79776 18.2392 3.68994C18.1686 3.58212 18.0624 3.49184 17.9322 3.42891C17.802 3.36599 17.6526 3.33282 17.5005 3.33301H2.5005C2.3487 3.33351 2.19995 3.36711 2.07023 3.43018C1.94052 3.49326 1.83475 3.58343 1.7643 3.691C1.69386 3.79857 1.6614 3.91947 1.67042 4.0407C1.67944 4.16192 1.7296 4.27888 1.8155 4.37901L9.3155 13.0457Z"
        fill="#bbbbbb"
      />
    </Svg>
  )
}
