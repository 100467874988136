import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { GlobalStyles } from '../../theme/GlobalStyles'
import { useWindowSize, useTheme } from '../../hooks'
import { Box } from '..'
import { Navbar, NAVBAR_HEIGHT } from './Navbar'
import { MobileNav } from './MobileNav'
import { Footer } from './Footer'

export const Layout: React.FC<{
  children: React.ReactNode
  transparentNavbarOnTop?: boolean
}> = ({ children, transparentNavbarOnTop }) => {
  const theme = useTheme()
  const [mobileNavVisible, setMobileNavVisible] = useState(false)
  const mediumBreakpoint = theme.breakpoints[2]

  useWindowSize((windowSize) => {
    if (windowSize.innerWidth >= mediumBreakpoint && mobileNavVisible) {
      setMobileNavVisible(false)
    }
  })

  return (
    <>
      <Helmet>
        <title>Incobrasa</title>
      </Helmet>
      <GlobalStyles />
      <Navbar
        mobileNavVisible={mobileNavVisible}
        transparentNavbarOnTop={transparentNavbarOnTop}
        onToggleNavbar={() => setMobileNavVisible(!mobileNavVisible)}
      />
      <MobileNav
        visible={mobileNavVisible}
        onHideMobileNav={() => setMobileNavVisible(false)}
      />
      <Box pt={transparentNavbarOnTop ? 'none' : NAVBAR_HEIGHT}>{children}</Box>
      <Footer />
    </>
  )
}
