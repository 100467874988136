import { SerializedStyles } from '@emotion/core'
import { partitionStyleProps, combineCssProperties } from '../theme/utils'
import type { StyleConfig } from '../theme/utils'
import { useTheme } from '.'

export function useStyleConfig(
  props: { [key: string]: any },
  styleConfig: StyleConfig,
  variantStyles: { [key: string]: any } = {}
): [SerializedStyles, { [key: string]: any }] {
  const theme = useTheme()
  const [forwardedProps, stylePropsInConfig] = partitionStyleProps(
    props,
    styleConfig
  )
  const serializedStyles = combineCssProperties(
    { ...variantStyles, ...stylePropsInConfig },
    styleConfig,
    theme
  )

  return [serializedStyles, forwardedProps]
}
