import React, { forwardRef } from 'react'
import { Box } from '../Box/Box'
import type { BoxProps } from '../Box/Box'

export interface ImageProps extends BoxProps {
  src: string
  alt: string
}

export const Image = forwardRef(
  (props: ImageProps, ref: React.Ref<HTMLImageElement>) => {
    return <Box as="img" ref={ref} display="block" maxWidth="100%" {...props} />
  }
)

Image.displayName = 'Image'
