import React, { useEffect, useRef, useState } from 'react'
import { Box } from '..'
import { BoxProps } from '../Box/Box'

interface MapProps extends BoxProps {
  lat?: number | string
  lng?: number | string
  address?: string
  zoom?: number | string
}

export const GoogleMap: React.FC<MapProps> = ({
  address,
  lat,
  lng,
  zoom = 12,
  ...props
}) => {
  const mapRef = useRef(null)
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(
    typeof window !== 'undefined' && !!window.google?.maps
  )

  function getGoogleMaps() {
    ;(window as any).googleMapsLoadedCallback = () => {
      setGoogleMapsLoaded(true)
      delete (window as any).googleMapsLoadedCallback
    }
    const script = document.createElement('script')
    const API_KEY = 'AIzaSyBgiXh9dNomGlaJaRiKLDsdLwawwYE7EDY'
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=googleMapsLoadedCallback`
    script.defer = true
    document.body.appendChild(script)
  }

  useEffect(() => {
    if (!googleMapsLoaded) {
      getGoogleMaps()
    }
  }, [googleMapsLoaded])

  useEffect(() => {
    function plotByAddress() {
      const mapOptions = {
        zoom: zoom
      }
      const map = new window.google.maps.Map(mapRef.current, mapOptions)
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results: any, status: string) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location)
          new window.google.maps.Marker({
            map,
            position: results[0].geometry.location
          })
        }
      })
    }
    if (googleMapsLoaded && address) {
      plotByAddress()
    }
  }, [address, zoom, googleMapsLoaded])

  useEffect(() => {
    function plotByCoordinates() {
      const location = {
        lat: Number(lat),
        lng: Number(lng)
      }
      const mapOptions = {
        center: location,
        zoom: zoom
      }
      const map = new window.google.maps.Map(mapRef.current, mapOptions)
      new window.google.maps.Marker({
        position: location,
        map
      })
    }

    if (googleMapsLoaded && lat && lng) {
      plotByCoordinates()
    }
  }, [lat, lng, zoom, googleMapsLoaded])

  return <Box ref={mapRef} width="100%" height="300" {...props} />
}
