import React, { forwardRef } from 'react'
import { Box } from '../Box/Box'
import type { BoxProps } from '../Box/Box'

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse'

type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse'

interface FlexProps extends BoxProps {
  direction?: FlexDirection | Array<FlexDirection>
  wrap?: FlexWrap | Array<FlexWrap>
}

export const Flex = forwardRef(
  (
    { children, direction, wrap, ...props }: FlexProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <Box
        ref={ref}
        display="flex"
        flexDirection={direction}
        flexWrap={wrap}
        {...props}
      >
        {children}
      </Box>
    )
  }
)

Flex.displayName = 'Flex'
